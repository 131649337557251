import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Result, School } from '../_jsonmodels/index';
import {JsonConvert, OperationMode, ValueCheckingMode} from "json2typescript"

const endpoint = 'http://localhost:8088/findSchool?';
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
  providedIn: 'root'
})
export class SchoolService {
    private subject = new Subject<any>();
    constructor(private http: HttpClient) { }
    private extractData(res: Response) {
        const body = res;
        return body || {};
    }

    updateSchools(searchText: string): void {
        console.log('inside updateSchools service');
        this.http.get(endpoint + 'pincode=' + searchText).pipe(
        map((res: any) => {
           
            let jsonConvert: JsonConvert = new JsonConvert();
            jsonConvert.operationMode = OperationMode.LOGGING; // print some debug data
            jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
            jsonConvert.valueCheckingMode = ValueCheckingMode.DISALLOW_NULL; // never allow null
            
            // Map to the Result class
            let result;
            try {
                result = jsonConvert.deserialize(res, Result);
                result.schools[0].printInfo(); 
            } catch (e) {
                console.log((<Error>e));
            }
            return result;
        }))
        .subscribe(data => {
            this.setSchools(data);
        });
    }

    setSchools(data: Result) {
        this.subject.next({result : data});
    }

    clearSchools() {
        this.subject.next();
    }

    getSchools(): Observable<any> {
        return this.subject.asObservable();
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            console.error(error); // log to console instead

            console.log(`${operation} failed: ${error.message}`);

            return of(result as T);
        };
    }
}


