import {JsonObject, JsonProperty} from "json2typescript";
import { Location } from './location.model'

@JsonObject("Viewport")
export class Viewport {
	@JsonProperty("northeast", Location, true)
	northeast: Location = undefined;
	
	@JsonProperty("southwest", Location, true)
	southwest: Location = undefined;
	
}