import {JsonObject, JsonProperty} from "json2typescript";
import { School } from './school.model';

@JsonObject("Result")
export class Result {
	@JsonProperty("status", String)    
	status: string = undefined;
	@JsonProperty("next_page_token", String)
	next_page_token: string = undefined;
	@JsonProperty("results", [School], true)
    schools: School[] = undefined;
}










