import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component, Input, ViewChild, NgZone, OnInit } from '@angular/core';
import { AgmCoreModule, MapsAPILoader, AgmMap } from '@agm/core';
import { GoogleMapsAPIWrapper } from '@agm/core/services';
import {SchoolService} from '../_services/index';
import { Subscription, Observable } from 'rxjs';
import { Result } from '../_jsonmodels/index';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css']
})

export class MapsComponent implements OnInit {
  geocoder:any;
eventsSubscription:any;
@Input() result: Result;
@Input() events: Observable<void>;
zoom: number = 13;
@ViewChild(AgmMap) map: AgmMap;

  constructor(public mapsApiLoader: MapsAPILoader,
              private zone: NgZone,
              private wrapper: GoogleMapsAPIWrapper,
              private schoolService: SchoolService) {
    this.mapsApiLoader = mapsApiLoader;
    this.zone = zone;
    this.wrapper = wrapper;
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }  
  ngOnInit() {
	  this.eventsSubscription = this.events.subscribe(() => {
		  console.log("Resizing map");
	        this.map.triggerResize()
	  });

  }
  
	ngOnDestroy() {
	  this.eventsSubscription.unsubscribe()
	}
}
