import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../_services/index';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css'],
  providers: [ApiService]
})
export class BodyComponent implements OnInit {
  constructor(private apiService: ApiService) {
      this.apiService = apiService;
   }

  ngOnInit() {
  }
  handleButtonEvent(event) {
    alert('button is clicked');
    console.log(event);
    this.apiService.getSchools()
        .subscribe(data => console.log(data));
  }
}
