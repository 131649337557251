import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accomplishment',
  templateUrl: './accomplishment.component.html',
  styleUrls: ['./accomplishment.component.css']
})
export class AccomplishmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
