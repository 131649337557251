import { Component, OnInit, Input, Injectable } from '@angular/core';
import {SchoolService} from '../_services/index';
import { Subject, Subscription } from 'rxjs';
import { Result } from '../_jsonmodels/index';

@Component({
  selector: 'app-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.css']
})

export class SchoolsComponent implements OnInit {
	private eventsSubject: Subject<void> = new Subject<void>();
    result: Result;
    subscription: Subscription;
  constructor(schoolService: SchoolService) {
    this.subscription = schoolService.getSchools().subscribe(data => {
            console.log('01.schoolupdate notification received:' + data.result.status);
            this.result = data.result;
            this.emitEventToChild();
        });
   }

  ngOnInit() {
  }
  
  emitEventToChild() {
	  console.log("event triggered");
	  this.eventsSubject.next()
	}
}
