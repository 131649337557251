import { Component, OnInit, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { SchoolService } from '../_services/index';
import { Result } from '../_jsonmodels/index';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit {
    results: Result;
    subscription: Subscription;
    isActive = false;
    constructor(private schoolService: SchoolService) {
        this.subscription = schoolService.getSchools().subscribe(data => {
            this.results = data.result;
        });
    }

    ngOnInit() {
    }

    onChange(searchText) {
        if (searchText.length === 0) {
            this.isActive = true;
        } else {
            this.isActive = true;
        }
    }

    onClick(searchText) {
        console.log(event);

        console.log('inside onClick');
        this.schoolService.updateSchools(searchText);
    }
}
