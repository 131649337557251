import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { MapsComponent } from './maps/maps.component';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { HeaderComponent } from './header/header.component';
import { SchoolItemComponent } from './school-item/school-item.component';
import { StarsComponent } from './stars/stars.component';
import { SearchComponent } from './search/search.component';
import { FooterComponent } from './footer/footer.component';
import { SchoolsComponent } from './schools/schools.component';
import { BodyComponent } from './body/body.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { FeatureComponent } from './feature/feature.component';
import { AccomplishmentComponent } from './accomplishment/accomplishment.component';
import { DealsComponent } from './deals/deals.component';
import { RegisterComponent } from './register/register.component';
import { NotFoundComponent } from './not-found/not-found.component';

const appRoutes: Routes = [  
  { path: 'login', component: SignInComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'feature', component: FeatureComponent },
  { path: 'deals', component: DealsComponent },
  { path: 'search', component: SearchComponent },
  { path: 'school', component: SchoolsComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    MapsComponent,
    HeaderComponent,
    SchoolItemComponent,
    StarsComponent,
    SearchComponent,
    FooterComponent,
    SchoolsComponent,
    BodyComponent,
    SignInComponent,
    FeatureComponent,
    AccomplishmentComponent,
    DealsComponent,
    RegisterComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBqWBORervgf3lUytd45TzURZtKKZi40iQ'
    }),
    RouterModule.forRoot(appRoutes)
  ],
  providers: [GoogleMapsAPIWrapper],
  bootstrap: [AppComponent]
})
export class AppModule { }
