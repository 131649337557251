import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-stars',
    templateUrl: './stars.component.html',
    styleUrls: ['./stars.component.css']
})
export class StarsComponent implements OnInit {
    @Input('rating-value') ratingValue = 0;
    @Input() numOfReviews = 0;

    maxRating = 5;
    selectedRating = 0;
    ratingArr = [1, 2, 3, 4, 5];
    constructor() { }

    ngOnInit() {
    }

    onClick(ratingVal) {
        if (this.ratingValue === 1) { this.ratingValue = 0; } else { this.ratingValue = ratingVal; }
        this.selectedRating = this.ratingValue;
    }

    getRatingValue() {
        return new Array(this.ratingValue);
    }
}
