import {JsonObject, JsonProperty} from "json2typescript";

import { Location } from './location.model';
import { Viewport } from './viewport.model';

@JsonObject("Geometry")
export class Geometry {
	@JsonProperty("location", Location, true)
	location: Location = undefined;
    
	@JsonProperty("viewport", Viewport, true)
	viewport: Viewport = undefined;
	//@JsonProperty("viewport", String, true)
	//viewport: String = undefined;
}