import {JsonObject, JsonProperty} from "json2typescript";

@JsonObject("Photo")
export class Photo {
	@JsonProperty("height", Number)
	height: number = undefined;
	@JsonProperty("width", Number)
	width: number = undefined;
	@JsonProperty("photo_reference", String, true)
	photo_reference: string = undefined;
}