import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  lat = 51.678418;
  lng = 7.809007;

  constructor() { }

  ngOnInit() {
  }

}
/*
export class HeaderComponent {
  title = 'app works!';
}
export class HeaderComponent implements OnInit {

  bannerText = 'My Great Schools';
  // declared array of months.
  months = ["January", "Feburary", "March", "April", "May",
          "June", "July", "August", "September",
          "October", "November", "December"];
  constructor() { }

  ngOnInit() {
  }

}
*/
