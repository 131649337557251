import {JsonObject, JsonProperty} from "json2typescript";
import { Photo } from './photo.model';
import { Geometry } from './geometry.model';

@JsonObject("School")
export class School {
	@JsonProperty("id", String)
    id: string = undefined;
	@JsonProperty("name", String)
    name: string = undefined;
	@JsonProperty("rating", Number)
    rating: number = undefined;
	@JsonProperty("formatted_address", String)
    formatted_address: string = undefined;
	@JsonProperty("place_id", String)
    place_id: string = undefined;
	@JsonProperty("user_ratings_total", String)
    user_ratings_total: string = undefined;
	@JsonProperty("photos", [Photo], true)
	photos: Photo[] = undefined;
	@JsonProperty("geometry", Geometry, true)
	geometry: Geometry = undefined;
	
    printInfo() {
        console.log(this.id + ";name= " + name);
    }
    
}